import React, { Component } from "react"
import { globalHistory } from "@reach/router"
const path = globalHistory.location.pathname
const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Contact extends Component {
  
  state = { 
    name: "", email: "", message: "", department: "Press",
    successMessage: false,
  }

  handleSubmit = e => {
    fetch("https://getform.io/f/3c9be5b5-15ea-4786-a881-590db981ce0a", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then((response) => {
        this.setState({successMessage: true})
      })
      .catch(error => {
        alert(error)
        console.log('error')
      });

    e.preventDefault();
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  componentDidMount() {
    if ( path === "/contact#thanks") {
      this.setState({ successMessage: true })
    }
  }

  successMessage() {
    if ( this.state.successMessage ) {
      return ( 
        <div className="w-full border border-t-0 bg-k7green2 p-4">
          <div className="uppercase">Your Message Was Sent</div>
        </div>
      )
    } else {
      return("")
    }
  }
  
  render() {

    return (
      <div className="mx-6 mt-10 md:mt-6 lg:mt-6 xl:mt-6">
        
        <div className="w-full border p-4">
          <div className="text-4xl uppercase">Contact</div>
        </div>
        
        {this.successMessage()}

        <div className="w-full md:w-6/12 lg:w-6/12 xl:w-6/12 pa-6">
          <form 
            action="https://getform.io/f/3c9be5b5-15ea-4786-a881-590db981ce0a"
            name="contact" 
            method="post"
            onSubmit={this.handleSubmit}
          >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <input 
                className="w-1/2 border border-t-0 p-3 uppercase text-sm mono"
                placeholder="Name"
                type="text"
                name="name"
                onChange={this.handleChange}
              />
              <input 
                className="w-1/2 border border-l-0 border-t-0 p-3 uppercase text-sm mono" 
                placeholder="Email" 
                type="text" 
                name="email"
                onChange={this.handleChange}
              />
              <div className="w-full text-sm uppercase mono border p-3 border-t-0">
                <label className="mr-3">Department</label>
                <select 
                  name="department"
                  onChange={this.handleChange}
                  value={this.state.value}
                >
                  <option value="Press">Press</option>
                  <option value="Sync/Licensing">Sync/Licensing</option>
                  <option value="Customer Services">Customer Services</option>
                  <option value="General Enquires">General Enquires</option>
                </select>
              </div>
              <textarea 
                placeholder="YOUR MESSAGE..."
                rows="6" 
                className="w-full text-sm mono border p-3 border-t-0" 
                name="message" 
                onChange={this.handleChange}
              ></textarea>
              <button style={{marginTop: '-10px'}} className="p-4 px-8 text-sm bg-k7green hover:bg-k7green2 border border-t-0 uppercase mono bold" type="submit">Send</button>
          </form>
        </div>
      </div>

    )

  }

}

export default Contact
